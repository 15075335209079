import Page_InnerShadowPageSkinComponent from '@wix/thunderbolt-elements/src/thunderbolt-core-components/Page/viewer/skinComps/BasePage/InnerShadowPageSkin.skin';


const Page_InnerShadowPageSkin = {
  component: Page_InnerShadowPageSkinComponent
};


export const components = {
  ['Page_InnerShadowPageSkin']: Page_InnerShadowPageSkin
};

